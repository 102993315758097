<template>
  <div class="indexPage" 
  v-if="
    $route.query.source != 'workplace' || 
    ($route.query.source == 'workplace' && $route.query.idt)">
    <div class="content_heade">
      <div class="content_heade_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="(val, index) in breadcrumbData"
            :key="index"
            @click.native="reload(index)"
            >{{ val.label }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="content_heade_search">
        <el-select v-model="releaseValue" @change="handleRelease" clearable>
          <el-option
            v-for="item in releaseOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-if="languageList && languageList.length > 0"
          clearable
          v-model="languageCode"
          @change="handleLanguage"
          placeholder="Language"
        >
          <el-option
            v-for="item in languageList"
            :key="item.languageCode"
            :label="item.languageName"
            :value="item.languageCode"
          ></el-option>
        </el-select>
        <el-select
          v-if="!isArticle"
          clearable
          v-model="versionValue"
          @change="handleVersion"
          placeholder="Version"
        >
          <el-option
            v-for="item in versionOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="searchInput_box">
          <i class="el-icon-search" @click.stop="handleShowSearch('show')"></i>
          <input
            type="text"
            v-model="searchData.keyWord"
            @keyup.enter="hadleEnterSearch"
            ref="searchInput"
            class="searchInput"
            placeholder=" Enter keywords"
          />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="structureTree">
        <el-tree
          :data="articleTree"
          :props="defaultProps"
          :highlight-current="true"
          @node-click="handleNodeClick"
          auto-expand-parent
          :default-expanded-keys="treeExpandedKeys"
          :expand-on-click-node="false"
          icon-class="el-icon-arrow-right"
          node-key="menuId"
          ref="tree"
        >
        </el-tree>
      </div>
      <div class="content">
        <div
          class="content_info"
          v-loading="loading"
          v-if="articleData && articleData.length > 0"
        >
          <div
            class="content_info_item"
            v-for="val in articleData"
            :key="val.articleId"
          >
            <div class="content_info_item_cotent">
              <div class="info_item_title">
                <div class="title_box">
                  <p
                    class="title"
                    @click="goDetailPage(val)"
                    v-html="val.title"
                  ></p>
                  <span title="Recommend" v-if="val.recommendLevel == 1"
                    ><svg-icon icon-class="hot"></svg-icon
                  ></span>
                </div>
                <span class="Time"
                  >Post&nbsp;{{
                    val.releaseTime
                      ? moment(val.releaseTime).format("YYYY.MM.DD")
                      : "--"
                  }}</span
                >
              </div>
              <div class="info_item_text">
                <p
                  class="text"
                  @click="goDetailPage(val)"
                  v-html="val.content + '...'"
                ></p>
                <div class="info_item_btn">
                  <p class="data">
                    <span class="iconMargin_first"
                      ><svg-icon icon-class="downloads"></svg-icon
                      >{{ val.downloadsNum }}</span
                    >
                    <span
                      ><svg-icon icon-class="hits"></svg-icon
                      >{{ val.viewsNum }}</span
                    >
                  </p>
                  <p class="postTime">
                    <span
                      class="downBtn"
                      v-if="!isArticle"
                      @click="downloadPDF(val)"
                      >Download</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="content_info"
          v-if="articleData && articleData.length < 1"
          v-loading="loading"
        >
          <div class="iconBox">
            <svg-icon icon-class="nodate"></svg-icon>
            <p class="nodata">No Data</p>
          </div>
        </div>
        <div class="main_Pagination">
          <p class="result"><span>Result of Search: </span><span>{{Pagination.total.toLocaleString()}}</span></p>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="Pagination.pageNumber"
            :page-sizes="[10, 20, 50]"
            :page-size="Pagination.pageSize"
            layout="sizes, prev, pager, next"
            prev-text="Previous"
            next-text="Next"
            :total="Pagination.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { searchInfo, getArticleDetail, sendHtml,frontSave } from "../../api/common";
import {menuTreeList} from "../../api/login"
import {formatDate} from "@/utils/index.js"
export default {
  name: "indexPage",
  data() {
    return {
      treeExpandedKeys: [35],
      showSearchInput: false,
      loading: false,
      isArticle: false,
      //路由传过来的值
      routeQuery: {},
      //结构树
      articleTree: [],
      //结构树点击获取的menuid
      treeMenuId:null,
      defaultProps: {
        children: "children",
        label: "label",
      },
      //排序下拉框
      releaseValue: "",
      releaseOption: [
        {
          label: "Post Date",
          value: "1",
        },
        {
          label: "Recommend",
          value: "2",
        },
        // {
        //   label: "Download",
        //   value: "3",
        // },
        {
          label: "Page View",
          value: "4",
        },
      ],
      //版本下拉框
      versionValue: "",
      versionOption: [],
      //语言下拉框
      languageList: [],
      languageCode: "",
      //列表内容
      articleData: [],
      searchData: {
        keyWord: "",
        menuId: "",
        moduleId: "",
        productVersionId: "",
        pageNumber: 1,
        pageSize: 10,
        sortType: "",
      },
      Pagination: {
        total: 0,
        pageSize: 20,
        pageNumber: 1,
      },
      breadcrumbData: [],
    };
  },
  methods: {
    //面包屑刷新页面
    reload(index){
      if(index==2){
        this.$router.go(0)
      }
    },
    //显示隐藏搜索框
    handleShowSearch(val) {
      if (val == "show" && !this.showSearchInput) {
        this.showSearchInput = true;
        this.$refs.searchInput.focus();
        this.$refs.searchInput.style.width = "400px";
      } else {
        this.getSearchDetail(this.routeQuery.menuId);
      }
    },
    handleHiedSearch() {
      document.addEventListener("click", (e) => {
        if (this.$refs.searchInput) {
          if (!this.$refs.searchInput.contains(e.target)) {
            this.showSearchInput = false;
            this.$refs.searchInput.style.width = "20px";
            e.stopPropagation();
          }
        }
      });
    },
    //点击结构树
    handleNodeClick(val) {
      this.treeMenuId = val.menuId
      this.getSearchDetail(val.menuId);
    },
    //回车搜索
    hadleEnterSearch() {
      var menuId=this.treeMenuId!==null?this.treeMenuId:this.routeQuery.menuId
      this.getSearchDetail(menuId);
      // this.showSearchInput = false;
      // this.$refs.searchInput.style.width = "20px";
    },
    //跳转详情页
    goDetailPage(val) {
      let data = {
        articleType: val.articleType,
        articleId: val.articleId,
        keyword: this.searchData.keyWord
      };
      this.$router.push({
        path: "/detailPage",
        query: { articleData: JSON.stringify(data) },
      });
    },
    //获取列表
    getSearchDetail(id) {
      this.loading = true;
      let params = JSON.parse(JSON.stringify(this.searchData));
      params.menuId = id;
      params.pageNumber = this.Pagination.pageNumber;
      params.pageSize = this.Pagination.pageSize;
      params.sortType = this.releaseValue;
      params.productVersionId = this.versionValue;
      params.languageCode = this.languageCode;
      searchInfo(params)
        .then((res) => {
          this.Pagination.total = res.data.rows.total;
          this.articleData = res.data.rows.rows;
          this.breadcrumbData = res.data.rows.crumbsList;
          console.log( this.breadcrumbData,' this.breadcrumbData');
          this.versionOption =
            res.data.rows.productVersionList.length > 0
              ? res.data.rows.productVersionList
              : [];
          this.languageList =
            res.data.rows.languageList.length > 0
              ? res.data.rows.languageList
              : [];
          this.loading = false;
        })
        .catch((err) => {
          this.articleData = [];
          this.loading = false;
        });
    },
    //分页搜索
    handleCurrentChange(val) {
      this.Pagination.pageNumber = val;
      var menuId=this.treeMenuId!==null?this.treeMenuId:this.routeQuery.menuId
      this.getSearchDetail(menuId);
    },
    //切换每页显示数量
    handleSizeChange(val) {
      this.Pagination.pageSize = val;
      var menuId=this.treeMenuId!==null?this.treeMenuId:this.routeQuery.menuId
      this.getSearchDetail(menuId);
    },
    //排序搜索
    handleRelease(val) {
      this.releasevalue = val;
      var menuId=this.treeMenuId!==null?this.treeMenuId:this.routeQuery.menuId
      this.getSearchDetail(menuId);
    },
    //版本搜索
    handleVersion(val) {
      this.versionValue = val;
      var menuId=this.treeMenuId!==null?this.treeMenuId:this.routeQuery.menuId
      this.getSearchDetail(menuId);
    },
    //语言切换
    handleLanguage(val) {
      var menuId=this.treeMenuId!==null?this.treeMenuId:this.routeQuery.menuId
      this.getSearchDetail(menuId);
    },
    //路由变化时改变值
    doMemuActive(data) {
      this.routeQuery = data.params.data
        ? data.params.data
        : JSON.parse(localStorage.getItem("library_tree"));
      if (this.routeQuery.articleType == 0) {
        this.isArticle = true;
      } else {
        this.isArticle = false;
      }
      this.articleTree = [];
      this.articleTree.push(this.routeQuery);
      this.treeExpandedKeys = [];
      this.treeExpandedKeys.push(this.routeQuery.menuId);
      (this.versionValue = ""), this.getSearchDetail(this.routeQuery.menuId);
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(data.params.detailId);
      });
    },
    //下载pdf
    downloadPDF(val) {
      var parmas={
          keyword:'',
          articleId:val.articleId
        }
      getArticleDetail(parmas).then((res) => {
        let content = res.data.rows.articleInfo.content;
        let data = {
          filename: res.data.rows.articleInfo.title,
          articleId: res.data.rows.articleInfo.articleId,
          html: `<!DOCTYPE html>
          <html lang="zh">
          <head><meta charset="utf-8" /></head>
          <body>
          ${content}
          </body>
          </html>`,
        };
        sendHtml(data).then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          this.pdfUrl = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var filename = val.title;
          a.href = this.pdfUrl;
          if (filename.endsWith(".pdf")) {
            a.download = filename;
          } else {
            a.download = filename + ".pdf";
          }
          a.click();
        });
      });
    },
    upload() {
      this.$router.push({ path: "/markdown" });
    },
     // 统计来源是email和workplace
     postFrontSave() {
      if (this.$route.query.source) {
          const data = {
            sourceUrl: location.href,
            createDate: formatDate(new Date()),
            module: this.$route.query.module,
            source: this.$route.query.source,
            account:this.$route.query.idt || ''
          };
          frontSave(data);
        } 
    }
  },
  computed: {
    moment() {
      return moment;
    },
  },
  created() {
    // d365过来要跳idt登录
    if (this.$route.query.source == 'workplace') {
     if(this.$route.query.idt){
        localStorage.removeItem('boforeIdtUrl');
      }else{
        localStorage.setItem('boforeIdtUrl', location.href);
        const idtLogin = process.env.VUE_APP_IDT_LOGIN + '&redirect_uri=' + encodeURIComponent(location.origin+'/loginIdt');
        location.replace(idtLogin);
      }
    }
    // const url = decodeURIComponent(window.location.search)
    // let menuid = url.split('=')[1]
     const menuid = this.$route.query.module;
     this.$store.dispatch('menuTreeList',menuid).then(res=>{
       this.routeQuery = this.$route.params.data
      ? this.$route.params.data
      : JSON.parse(localStorage.getItem("library_tree"));
      console.log(this.routeQuery);
       if (this.routeQuery.articleType == 0) {
      this.isArticle = true;
    } else {
      this.isArticle = false;
    }
    this.treeExpandedKeys = [];
    this.treeExpandedKeys.push(this.routeQuery.menuId);
    if (
      this.$route.params.detailParId &&
      this.treeExpandedKeys.indexOf(Number(this.$route.params.detailParId)) ==
        -1
    ) {
      this.treeExpandedKeys.push(Number(this.$route.params.detailParId));
    }
    if (
      this.$route.params.detailId &&
      this.treeExpandedKeys.indexOf(Number(this.$route.params.detailId)) == -1
    ) {
      this.treeExpandedKeys.push(Number(this.$route.params.detailId));
    }
    this.articleTree = [];
    this.articleTree.push(this.routeQuery);
    if (this.$route.params.detailId) {
      this.getSearchDetail(this.$route.params.detailId);
    } else {
      this.getSearchDetail(this.routeQuery.menuId);
    }
    this.$nextTick(() => {
     this.$route.params.detailId?this.$refs.tree.setCurrentKey(this.$route.params.detailId):this.$refs.tree.setCurrentKey(menuid);//刷新页面会出现this.$route.params.detailId丢失
    });
    this.handleHiedSearch();
     })
   
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.tree.setCurrentKey(this.$route.params.detailId);
      this.postFrontSave();
    });
  },
  watch: {
    //监听路由变化
    $route(toRouter, fromRouter) {
      this.releaseValue=''
      this.languageCode=''
      this.doMemuActive(toRouter);
    },
  },
};
</script>

<style lang="scss" scoped>
.indexPage {
  width: 100%;
  .content_heade {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 41px 13px 30px;
    background: #324062;
    .content_heade_breadcrumb {
      flex: 6;
      // padding-left: 30px;
      .el-breadcrumb /deep/.el-breadcrumb__inner {
        color: #ffffff !important;
        
      }
      .el-breadcrumb__item:last-child /deep/.el-breadcrumb__inner{
        cursor: pointer;
      }
      .el-breadcrumb /deep/.el-breadcrumb__separator {
        color: #ffffff !important;
      }
    }
    .content_heade_search {
      display: flex;
      /deep/.el-input ::placeholder {
        color: #fff !important;
      }
      /deep/ .el-select {
        .el-input {
          .el-input__suffix {
            .el-select__caret {
              color: #fff;
            }
          }
        }
      }
      .searchInput_box {
        margin-left: 40px;
        transition: width 1s;
        display: flex;

        i {
          color: #2d2f36;
          padding: 0 0 0 20px;
          font-size: 18px;
          background: #fff;
          height: 38px;
          border-radius: 25px 0 0 25px;
          line-height: 38px;
          cursor: pointer;
        }
        .searchInput {
          width: 20px;
          border: 0;
          background: #fff;
          height: 38px;
          border-radius: 0 25px 25px 0;
          outline: none;
          padding-left: 20px;
          transition: all 0.5s;
        }
        input::placeholder {
          color: #fff;
        }
      }
    }
  }
  .main {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 160px);
    .structureTree {
      width: 15%;
      background: #fff;
      padding: 15px 0;
      height: 100%;
      /deep/.el-tree {
        overflow-y: auto;
        height: 100%;
      }
      .el-tree /deep/ .el-tree-node__expand-icon.expanded {
        display: flex;
        height: 100%;
      }
      .el-tree /deep/ .el-tree-node__content > .el-tree-node__expand-icon {
        display: flex;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
      }
      .el-tree
        /deep/
        .el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content {
        background: #0d39a8 !important;
      }
      // .el-tree /deep/ .el-icon-caret-right:before {
      //   background: url("../../assets/img/close.png") no-repeat 0 3px;
      //   content: "";
      //   display: block;
      //   width: 16px;
      //   height: 16px;
      //   font-size: 16px;
      //   background-size: 16px;
      // }
      .el-tree /deep/ .el-tree-node__content {
        font-size: 16px;
        height: auto;
        // padding-top: 7px !important;
        display: flex;
        align-items: baseline;
        padding: 10px 0;
      }
      // .el-tree /deep/ .el-tree-node__content .is-leaf:before {
      //   content: " ";
      //   width: 20px;
      // }
      // .el-tree /deep/ .el-tree-node__expand-icon.is-leaf {
      //   // display: none;
      // }
      .el-tree /deep/ .el-tree-node__label {
        width: 250px;
        white-space: normal;
        display: flex;
        height: 100%;
        font-size: 16px;
        // padding-left: 8px;
      }
    }
    .content {
      width: 83%;
      padding: 0 30px 0 0;
      height: 100%;

      .content_info {
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: auto;
        background: #fff;
        .content_info_item {
          width: 100%;
          background: #fff;
          // border-radius: 6px;
          // margin: 30px 0;
          // padding: 25px 53px 21px 20px;
          padding: 0 53px 0 20px;
          // box-shadow: 0px 4px 16px rgba(107, 134, 203, 0.3);
          .content_info_item_cotent {
            padding: 15px 0px 10px 0px;
            border-bottom: 1px solid #eee;
            .info_item_title {
              display: flex;
              justify-content: space-between;
              padding: 0 0 10px 0;
              .title_box {
                display: flex;
                align-items: center;
                .title {
                  color: #0d39a8;
                  font-size: 20px;
                  cursor: pointer;
                }
                .svg-icon {
                  color: red;
                  font-size: 24px;
                  margin-left: 5px;
                }
              }
              .Time {
                color: #7a7b80;
                font-family: Source Han Sans CN;
              }
            }
            .info_item_text {
              width: 100%;
              display: flex;
              align-items: flex-end;
              flex-direction: column;
              .text {
                width: 65%;
                color: #7a7b80;
                line-height: 20px;
                cursor: pointer;
                display: inline-block;
                width: 100%;
                word-break: break-word;
                font-size: 14px;
              }
              .info_item_btn {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                .data {
                  display: flex;
                  span {
                    margin-right: 38px;
                    color: #ababae;
                    .svg-icon {
                      color: #6d88cb;
                      margin-right: 7px;
                    }
                  }
                }
                .postTime {
                  text-align: right;
                  width: 35%;
                  font-size: 16px;
                  span {
                    color: #7a7b80;
                    font-size: 16px;
                  }
                  .downBtn {
                    font-size: 16px;
                    color: #fff;
                    padding: 8px 16px;
                    background: #71b32f;
                    margin-left: 24px;
                    border-radius: 20px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .iconBox {
          width: fit-content;
          margin: 0 auto;
          text-align: center;
          .svg-icon {
            font-size: 80px;
          }
          .nodata {
            text-align: center;
          }
        }
      }
    }
    .main_Pagination {
      width: 100%;
      padding: 20px 360px 25px 360px;
      position: relative;
      .result{
        position: absolute;
        left: 50px;
        top: 25px;
        color: #7A7B80;
      }
    }
  }
}
</style>